
















































































































































































::v-deep .v-dialog--active {
  border-radius: 20px;
}

.v-main {
  height: 100vh;
}

.popAlert {
  position: fixed;
  bottom: 0;
  z-index: 900;
  width: 60%;
  left: calc((100% - 60%) / 2);
}

.panel {
  left: 20%;
  width: 60%;
  position: fixed;
  top: 64px;
}

.fletrix-link {
  color: white !important;
  text-decoration: none;
  margin-right: 12px;
}
