.itemFieldName {
  font-weight: 600;
  color: var(--v-itemLabel-base);
  font-size: 14px;
}
.mainTitle {
  color: var(--v-mainTitleLabel-base);
  font-size: 17px;
  font-weight: 600;
}
.textLabel {
  color: #8898aa;
  font-size: 14px;
}
.subTitle {
  color: var(--v-mainTitleLabel-base);
  font-size: 14px;
  //font-weight: 600;
}