



















































































.v-list-child {
  padding-left: 40px;
  transition: padding-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.v-navigation-drawer--mini-variant {
  .v-list-child {
    padding-left: 16px;
  }
}

.text-icon {
  font-style: inherit;
  font-size: inherit;
  text-align: center;
  width: 100%;
}
